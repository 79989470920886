<template>
  <!--begin::List Widget 5-->
  <div class="card" :class="widgetClasses">
    <!--begin::Header-->
    <div class="card-header align-items-center border-0 mt-4">
      <h3 class="card-title align-items-start flex-column">
        <span class="fw-bolder mb-2 text-dark">{{
          agenda[$language.current].title
        }}</span>
        <span class="text-muted fw-bold fs-7">{{
          agenda[$language.current].subtitle
        }}</span>
      </h3>

      <div class="card-toolbar">
        <!--begin::Menu-->
        <button
          type="button"
          class="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
          data-kt-menu-trigger="click"
          data-kt-menu-placement="bottom-end"
          data-kt-menu-flip="top-end"
        >
          <span class="svg-icon svg-icon-2">
            <inline-svg
              src="media/icons/duotone/Layout/Layout-4-blocks-2.svg"
            />
          </span>
        </button>
        <!--end::Menu-->
      </div>
    </div>
    <!--end::Header-->

    <!--begin::Body-->
    <div class="card-body pt-5">
      <!--begin::Timeline-->
      <div class="timeline-label">
        <template
          v-for="(item, index) in agenda[$language.current].items"
          :key="index"
        >
          <!--begin::Item-->
          <div class="timeline-item">
            <!--begin::Label-->
            <div
              :class="`timeline-label fw-bolder text-${item.date_color} fs-6`"
            >
              {{ item.date }}
            </div>
            <!--end::Label-->

            <!--begin::Badge-->
            <div class="timeline-badge">
              <i :class="`fa fa-genderless text-${item.badge_color} fs-1`"></i>
            </div>
            <!--end::Badge-->
            <!--begin::Text-->
            <div
              :class="`fw-mormal timeline-content text-${item.text_color} ps-3`"
            >
              {{ item.text }}
            </div>
            <!--end::Text-->
          </div>
          <!--end::Item-->
        </template>
      </div>
      <!--end::Timeline-->
    </div>
    <!--end: Card Body-->
  </div>
  <!--end: List Widget 5-->
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";
import { useStore } from "vuex";
//import Dropdown1 from "@/components/dropdown/Dropdown1.vue";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "kt-widget-5",
  props: {
    widgetClasses: String,
  },
  data() {
    const obj = {
      title: "Title",
      subtitle: "Subtitle",
    };
    const agenda = { sl: obj, en: obj };
    return {
      agenda,
    };
  },
  created() {
    const store = useStore();
    store.dispatch(Actions.AGENDA).then((res) => {
      this.agenda = res.data;
    });
  },
  setup() {
    onMounted(() => {
      MenuComponent.reinitialization();
    });
  },
});
</script>
