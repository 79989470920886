
import { useStore } from "vuex";
import { getToken, ID_TOKEN_KEY } from "@/core/services/JwtService";
import { Actions } from "@/store/enums/StoreEnums";
import { defineComponent, onMounted } from "vue";
//import TableWidget9 from "@/components/widgets/tables/Widget9.vue";
//import TableWidget5 from "@/components/widgets/tables/Widget5.vue";
import TableWidget13 from "@/components/widgets/tables/Widget13.vue";
import TableWidget14 from "@/components/widgets/tables/Widget14.vue";
//import TableWidget12 from "@/components/widgets/tables/Widget12.vue";
//import ListWidget1 from "@/components/widgets/lists/Widget1.vue";
//import ListWidget2 from "@/components/widgets/lists/Widget2.vue";
//import ListWidget3 from "@/components/widgets/lists/Widget3.vue";
import ListWidget5 from "@/components/widgets/lists/Widget5.vue";
//import ListWidget6 from "@/components/widgets/lists/Widget6.vue";
import MixedWidget2 from "@/components/widgets/mixed/Widget2.vue";
//import MixedWidget5 from "@/components/widgets/mixed/Widget5.vue";
//import MixedWidget7 from "@/components/widgets/mixed/Widget7.vue";
//import MixedWidget10 from "@/components/widgets/mixed/Widget10.vue";
import StatisticsWidget4 from "@/components/widgets/statsistics/Widget4.vue";
//import StatisticsWidget7 from "@/components/widgets/statsistics/Widget7.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

interface Wrap {
  label: string;
  color: string;
}
interface Datetime {
  date: string;
  time: string;
  timeRemaining: string;
  color: string;
}
interface Output {
  packets: Array<{
    packetId: number;
    s: number;
    date: number;
    products: Array<{
      name: string;
      count: string;
    }>;
    customer: {
      name: string;
      lastname: string;
      phone: string;
      image: string;
    };
    payments: Array<{
      type: string;
      amount: number;
    }>;
    status: Wrap;
    action: Wrap;
    datetime: Datetime;
  }>;
}

export default defineComponent({
  name: "dashboard",
  components: {
    //TableWidget9,
    //TableWidget5,
    //ListWidget1,
    //ListWidget2,
    //ListWidget3,
    ListWidget5,
    //ListWidget6,
    MixedWidget2,
    //MixedWidget5,
    StatisticsWidget4,
    //StatisticsWidget7,
    TableWidget13,
    TableWidget14,
    //TableWidget12
  },
  setup() {
    const store = useStore();
    const token = getToken(ID_TOKEN_KEY);
    store.dispatch(Actions.LOGIN_TOKEN_CHANGED, token);

    onMounted(() => {
      setCurrentPageTitle("Dashboard");
    });
  },
});
