
import { defineComponent } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "kt-widget-4",
  props: {
    widgetClasses: String,
    svgIcon: String,
    switcher: String,
    color: String,
    svgColor: String,
    height: Number,
  },
  data() {
    const store = useStore();
    return {
      store,
    };
  },
  components: {},
});
