
import { defineComponent, onMounted } from "vue";
import { useStore } from "vuex";
//import Dropdown1 from "@/components/dropdown/Dropdown1.vue";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "kt-widget-5",
  props: {
    widgetClasses: String,
  },
  data() {
    const obj = {
      title: "Title",
      subtitle: "Subtitle",
    };
    const agenda = { sl: obj, en: obj };
    return {
      agenda,
    };
  },
  created() {
    const store = useStore();
    store.dispatch(Actions.AGENDA).then((res) => {
      this.agenda = res.data;
    });
  },
  setup() {
    onMounted(() => {
      MenuComponent.reinitialization();
    });
  },
});
