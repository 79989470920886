<template>
  <!--begin::Statistics Widget 4-->
  <div :class="widgetClasses" class="card">
    <!--begin::Body-->
    <div class="card-body py-3">
      <template v-if="store.state.PacketsModule.chartData != null">
        <!--<label>chartData:</label>
        <p>{{ store.state.PacketsModule.chartData }}</p>-->
        <div class="tab-content">
          <!--begin::Tap pane-->
          <div class="tab-pane fade tab1 active show" id="kt_tab_1">
            <div class="d-flex flex-stack card-p flex-grow-1">
              <span class="symbol symbol-50px me-2">
                <span :class="`bg-light-${svgColor}`" class="symbol-label">
                  <span
                    :class="`svg-icon-${svgColor}`"
                    class="svg-icon svg-icon-3x"
                  >
                    <inline-svg :src="svgIcon" />
                  </span>
                </span>
              </span>

              <div class="d-flex flex-column text-end">
                <span class="text-dark fw-bolder fs-2">{{
                  store.state.PacketsModule.chartData[0 + Number(switcher)]
                    .title
                }}</span>
                <span class="text-muted fw-bold mt-1">{{
                  store.state.PacketsModule.chartData[0 + Number(switcher)]
                    .description
                }}</span>
              </div>
            </div>
            <apexchart
              class="statistics-widget-3-chart card-rounded-bottom"
              :options="
                store.state.PacketsModule.chartData[0 + Number(switcher)]
                  .chartOptions
              "
              :series="
                store.state.PacketsModule.chartData[0 + Number(switcher)].series
              "
              :height="height"
              type="area"
            ></apexchart>
          </div>
          <div class="tab-pane fade tab2" id="kt_tab_2">
            <div class="d-flex flex-stack card-p flex-grow-1">
              <span class="symbol symbol-50px me-2">
                <span :class="`bg-light-${svgColor}`" class="symbol-label">
                  <span
                    :class="`svg-icon-${svgColor}`"
                    class="svg-icon svg-icon-3x"
                  >
                    <inline-svg :src="svgIcon" />
                  </span>
                </span>
              </span>

              <div class="d-flex flex-column text-end">
                <span class="text-dark fw-bolder fs-2">{{
                  store.state.PacketsModule.chartData[1 + Number(switcher)]
                    .title
                }}</span>

                <span class="text-muted fw-bold mt-1">{{
                  store.state.PacketsModule.chartData[1 + Number(switcher)]
                    .description
                }}</span>
              </div>
            </div>
            <apexchart
              class="statistics-widget-3-chart card-rounded-bottom"
              :options="
                store.state.PacketsModule.chartData[1 + Number(switcher)]
                  .chartOptions
              "
              :series="
                store.state.PacketsModule.chartData[1 + Number(switcher)].series
              "
              :height="height"
              type="area"
            ></apexchart>
          </div>
          <div class="tab-pane fade tab3" id="kt_tab_3">
            <div class="d-flex flex-stack card-p flex-grow-1">
              <span class="symbol symbol-50px me-2">
                <span :class="`bg-light-${svgColor}`" class="symbol-label">
                  <span
                    :class="`svg-icon-${svgColor}`"
                    class="svg-icon svg-icon-3x"
                  >
                    <inline-svg :src="svgIcon" />
                  </span>
                </span>
              </span>

              <div class="d-flex flex-column text-end">
                <span class="text-dark fw-bolder fs-2">{{
                  store.state.PacketsModule.chartData[2 + Number(switcher)]
                    .title
                }}</span>

                <span class="text-muted fw-bold mt-1">{{
                  store.state.PacketsModule.chartData[2 + Number(switcher)]
                    .description
                }}</span>
              </div>
            </div>
            <apexchart
              class="statistics-widget-3-chart card-rounded-bottom"
              :options="
                store.state.PacketsModule.chartData[2 + Number(switcher)]
                  .chartOptions
              "
              :series="
                store.state.PacketsModule.chartData[2 + Number(switcher)].series
              "
              :height="height"
              type="area"
            ></apexchart>
          </div>
        </div>
      </template>
    </div>
    <!--end::Body-->
  </div>
  <!--end::Statistics Widget 4-->
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "kt-widget-4",
  props: {
    widgetClasses: String,
    svgIcon: String,
    switcher: String,
    color: String,
    svgColor: String,
    height: Number,
  },
  data() {
    const store = useStore();
    return {
      store,
    };
  },
  components: {},
});
</script>
